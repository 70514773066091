<template>
  <div>
    <cta-button
      :class="'cs-' + ctaResponse.ctaType + '-btn'"
      v-for="ctaResponse in statusResponse"
      :key="ctaResponse.ctaType + '-' + ctaResponse.vin"
      :status-response="ctaResponse"
      :width="width"
      :height="height"
      :font-size="fontSize"
      :font="font"
      :font-color="fontColor"
      :text-align="textAlign"
      :padding="padding"
      :margin="margin"
      :background-color="backgroundColor"
      :hover-background-color="hoverBackgroundColor"
      :test="test"
      :dev="dev"
      :border-radius="borderRadius"
      :details="details"
      :overlay-enabled="ctaResponse.overlayEnabled"
      :third-party-cookies-enabled="thirdPartyCookiesEnabled"
      @click.native="onClickHandler(ctaResponse)"
    >
      <slot>Buy@Home</slot>
    </cta-button>
  </div>
</template>

<script>
import lodashGet from "lodash/get";
import lodashIsNil from "lodash/isNil";
import lodashIndexOf from "lodash/indexOf";
import lodashToLower from "lodash/toLower";
import lodashToUpper from "lodash/toUpper";
import api from "./api";
import "./showComponentOnLoad";
import CtaButton from "./CtaButton.vue";
import cookieTest from "@/cookietest";

const CS_DISABLED_DEALER_IDS_KEY = "disable-cs-button-v2";

export default {
  name: "CsBuyWidget",
  components: { CtaButton },
  props: {
    vin: {
      type: String,
      required: true,
    },
    ctaType: {
      type: String,
      required: false,
      default: "T,A,P",
    },
    dealerId: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
    provider: {
      type: String,
      required: false,
      default: "",
      validator: function (value) {
        return ["nissan", ""].indexOf(lodashToLower(value)) !== -1;
      },
    },
    source: {
      type: String,
      required: false,
      default: "dealer-plugin",
    },
    dev: {
      type: Boolean,
      required: false,
      default: false,
    },
    dealType: {
      type: String,
      required: false,
      default: "LOWEST",
      validator: function (value) {
        return (
          ["LEASE", "FINANCE", "LOWEST"].indexOf(lodashToUpper(value)) !== -1
        );
      },
    },
    width: {
      type: String,
      required: false,
      default: null,
    },
    height: {
      type: String,
      required: false,
      default: null,
    },
    fontSize: {
      type: String,
      required: false,
      default: null,
    },
    font: {
      type: String,
      required: false,
      default: null,
    },
    fontColor: {
      type: String,
      required: false,
      default: "#fff",
    },
    textAlign: {
      type: String,
      required: false,
      default: "center",
      validator: function (value) {
        return ["CENTER", "LEFT", "RIGHT"].indexOf(lodashToUpper(value)) !== -1;
      },
    },
    padding: {
      type: String,
      required: false,
      default: "10px",
    },
    margin: {
      type: String,
      required: false,
      default: null,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#c3002f",
    },
    hoverBackgroundColor: {
      type: String,
      required: false,
      default: "#9d0026",
    },
    test: {
      type: Boolean,
      required: false,
      default: false,
    },
    borderRadius: {
      type: String,
      required: false,
      default: null,
    },
    details: {
      type: Boolean,
      required: false,
      default: false,
    },
    overlayEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      debugMode: false, //verbose logging
      statusResponse: [],
      hover: false,
      showModal: false,
      selectedVehicle: null,
      thirdPartyCookiesEnabled: null,
      wideViewEnabled: false,
      viewOptions: {
        wide: "csw-wide-view",
        quarter: "csw-quarter-view",
      },
      scriptHtml: `
        function csw_outsideClickEvent(event) {
          if (event.target.classList.contains("csw-overlay")) {
            csw_closeModal();
          }
        };

        function csw_closeModal() {
          const el = document.querySelector(".csw-overlay-container");
          el.remove();
        };
      `,
    };
  },
  computed: {
    viewOptionStyle() {
      return this.normalizeBoolean(this.wideViewEnabled)
        ? this.viewOptions.wide
        : this.viewOptions.quarter;
    },
    validCtaTypes() {
      let ctas = this.ctaType;
      if (Object.keys(this.ctaType).length == 0) {
        ctas = "P";
      }
      return ctas;
    },
    modalHtml() {
      return `<div class="csw-overlay" :id="${this.selectedVehicle.vin}" onclick="csw_outsideClickEvent(event)">
        <div class="csw-modal-container ${this.viewOptionStyle}">
          <div class="csw-modal-close-container">
            <a class="csw-modal-close-link" onclick="document.querySelector('.csw-overlay-container').remove();"> X </a>
          </div>
          <div class="csw-modal">
            <iframe
              class="csw-iframe"
              :src=""
              width="100%"
              height="95%"
            ></iframe>
          </div>
        </div>
      </div>`;
    },
    style() {
      const style = {
        color: this.escapeBadCharacters(this.fontColor),
      };

      if (this.hover) {
        style.backgroundColor = this.escapeBadCharacters(
          this.hoverBackgroundColor
        );
      } else {
        style.backgroundColor = this.escapeBadCharacters(this.backgroundColor);
      }

      if (!lodashIsNil(this.width)) {
        style.width = this.escapeBadCharacters(this.width);
      }

      if (!lodashIsNil(this.height)) {
        style.height = this.escapeBadCharacters(this.height);
      }

      if (!lodashIsNil(this.fontSize)) {
        style.fontSize = this.escapeBadCharacters(this.fontSize);
      }

      if (!lodashIsNil(this.textAlign)) {
        style.textAlign = this.escapeBadCharacters(this.textAlign);
      }

      if (!lodashIsNil(this.padding)) {
        style.padding = this.escapeBadCharacters(this.padding);
      }

      if (!lodashIsNil(this.margin)) {
        style.margin = this.escapeBadCharacters(this.margin);
      }

      if (!lodashIsNil(this.font)) {
        style.font = this.escapeBadCharacters(this.font);
      } else {
        const fontSize = lodashIsNil(this.fontSize)
          ? "14px"
          : this.escapeBadCharacters(this.fontSize);

        style.font = `bold ${fontSize} Helvetica, Arial, sans-serif`;
      }

      if (!lodashIsNil(this.borderRadius)) {
        style.borderRadius = this.escapeBadCharacters(this.borderRadius);
      }

      return style;
    },
  },
  created() {
    this.onCreated();
  },
  mounted() {
    const that = this;
    window.setTimeout(() => that.$emit("onload"), 1);

    cookieTest("https://shopathome.carsaver.com/cookietest", (message) => {
      if (this.debugMode) console.log("cscookieresult", message.cscookieresult);

      if (message && "cscookieresult" in message) {
        this.thirdPartyCookiesEnabled = message.cscookieresult;
      }
    });
  },
  methods: {
    normalizeBoolean(val) {
      if (lodashIsNil(val)) {
        return false;
      }

      if (typeof val === "string") {
        const lowerVal = lodashToLower(val);
        return lowerVal === "false" ? false : true;
      }

      return val;
    },
    onClickHandler(ctaResponse) {
      this.selectedVehicle = ctaResponse;
      const url = this.selectedVehicle.entryPoint;
      const btnText = this.selectedVehicle.style.name;
      let clickEvent = new CustomEvent("carSaverWidgetClicked", {
        detail: { label: btnText, url: url },
      });
      document.dispatchEvent(clickEvent);

      if (this.debugMode) console.log("cs-buy-widget click event:", clickEvent);

      if (this.selectedVehicle.overlayEnabled) {
        if (this.thirdPartyCookiesEnabled) {
          // If third party cookies are enabled, emit event to open iframe
          this.openModal();
        } else {
          // If third party cookies are not enabled, redirect URL in the same window tab
          window.open(url, "_self");
        }
      } else {
        // Overlay is not enabled, open in new window tab
        window.open(url, "_blank");
      }
    },
    teleportIframe() {
      const styleId = "csw-overlay-styles";
      const scriptId = "csw-overlay-script";
      const newEl = document.createElement("div");
      const newScript = document.createElement("script");
      const p = window.parent.document.querySelectorAll("cs-buy-widget-v2");
      const styles = p[0].shadowRoot.querySelectorAll("style")[0];
      const clonedStyles = styles.cloneNode(true);

      newEl.classList.add("csw-overlay-container");
      newEl.innerHTML = this.modalHtml;
      newScript.id = scriptId;
      newScript.innerHTML = this.scriptHtml;
      clonedStyles.id = styleId;

      const parentStyles = window.parent.document.querySelector(`#${styleId}`);
      const parentScript = window.parent.document.querySelector(`#${scriptId}`);

      if (!parentStyles) {
        window.parent.document.body.appendChild(clonedStyles);
      }
      if (!parentScript) {
        window.parent.document.body.appendChild(newScript);
      }

      window.parent.document.body.appendChild(newEl);
      const iframe = window.parent.document.querySelector(".csw-iframe");
      iframe.src = this.selectedVehicle.entryPoint;
    },
    removeIfame() {
      const el = window.parent.document.querySelector(".csw-overlay-container");
      el.remove();
    },
    closeModal() {
      this.showModal = false;
      this.selectedVehicle = null;
      this.removeIfame();
    },
    openModal() {
      this.showModal = true;
      this.teleportIframe();
    },
    onCreated() {
      if (this.dev === true) {
        // eslint-disable-next-line
        console.warn(
          "Running CarSaver Buy Button Widget in DEV mode.  Remove 'dev' attribute in production."
        );
      }

      if (this.test === true) {
        // eslint-disable-next-line
        console.warn(
          "Running CarSaver Buy Button Widget in TEST mode.  Remove 'test' attribute in production."
        );
      }

      const myStorage = window.sessionStorage;

      const storageDisabledDealerIds = JSON.parse(
        myStorage.getItem(CS_DISABLED_DEALER_IDS_KEY) || "[]"
      );

      if (lodashIndexOf(storageDisabledDealerIds, this.dealerId) !== -1) {
        this.logDebug("Disabling button due to session disablement.");

        this.statusResponse = [];

        return;
      }

      if (lodashIsNil(this.vin) || this.vin.length != 17) {
        console.warn("Vin length invalid: ", this.vin);
        return;
      }

      const domain = location.hostname;
      const widgetReferrer = location.href;
      const baseUri =
        this.dev === true
          ? "https://api-beta.carsaver.com"
          : "https://api.carsaver.com";

      const endpoint = baseUri + "/apex/dealerplugin/v2/vehicle/active";
      const query = {
        dealerId: this.escapeBadCharacters(this.dealerId),
        vin: this.vin,
        campaignId: this.escapeBadCharacters(this.campaignId),
        provider: this.escapeBadCharacters(this.provider),
        domain: encodeURIComponent(domain),
        ctaTypes: this.validCtaTypes,
        widgetReferrer: encodeURIComponent(widgetReferrer),
      };

      if (this.debugMode) console.log("cs-buy-widget query:", query);

      api
        .get(endpoint, query)
        .then((resp) => {
          this.statusResponse = lodashGet(resp, "data", {});

          // setting the view option
          // Per page setting, only the first response is used
          this.wideViewEnabled = this.normalizeBoolean(
            this.statusResponse[0].wideViewEnabled
          );

          if (!this.statusResponse) {
            const message = lodashGet(this.statusResponse, "message", "") || "";

            if (!this.statusResponse.dealerEnabled) {
              this.logDebug(`Disabling button for session due to: ${message}`);

              this.disableDealerId(this.dealerId);
            }
          }

          if (this.debugMode) console.log("cs-buy-widget response:", resp);

          // Determine if we should inject the Nissan font
          this.shouldNissanFontBeInjected(this.statusResponse[0].style.font);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("cs-buy-widget error:", error);
        });
    },

    disableDealerId(dealerId) {
      const myStorage = window.sessionStorage;
      const disabledIds = JSON.parse(
        myStorage.getItem(CS_DISABLED_DEALER_IDS_KEY) || "[]"
      );
      if (lodashIndexOf(disabledIds, dealerId) === -1) {
        disabledIds.push(dealerId);
        myStorage.setItem(
          CS_DISABLED_DEALER_IDS_KEY,
          JSON.stringify(disabledIds)
        );
      }
    },

    logDebug(message) {
      if (this.dev === true) {
        console.info(message);
      }
    },

    escapeBadCharacters(value) {
      if (lodashIsNil(value)) {
        return value;
      }
      return value.replace(/[\u201D]/g, "");
    },

    shouldNissanFontBeInjected(ctaFont) {
      if (ctaFont.toLowerCase().trim() === "nissanbrand") {
        const regularFont = new FontFace(
          "nissanbrand",
          "url(https://nissan-brand-fonts.carsaver.com/nissan-brand-regular.otf)",
          { weight: "400", style: "normal" }
        );

        const boldFont = new FontFace(
          "nissanbrand",
          "url(https://nissan-brand-fonts.carsaver.com/nissan-brand-bold.otf)",
          { weight: "700", style: "normal" }
        );

        // Load and add the regular font
        regularFont
          .load()
          .then((font) => {
            document.fonts.add(font);
          })
          .catch((err) =>
            console.error("Error loading nissan brand regular font:", err)
          );

        // Load and add the bold font
        boldFont
          .load()
          .then((font) => {
            document.fonts.add(font);
          })
          .catch((err) =>
            console.error("Error loading nissan brand bold font:", err)
          );
      }
    },
  },
  watch: {
    vin: function () {
      this.onCreated();
      if (this.debugMode) console.log("vin changed", this.vin);
    },
  },
};
</script>

<style lang="scss">
.csw-buy-button {
  position: relative;
  border-radius: 0;
  color: white;
  border: none;
  cursor: pointer;

  &:not(:defined) {
    display: none;
  }

  .csw-svg {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
    height: 1em;
    user-select: none;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
}
.csw-overlay {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.5);
}
.csw-wide-view {
  max-width: calc(3 / 4 * 100vw);
}
.csw-quarter-view {
  max-width: 420px;
}
.csw-modal-container {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  background-color: #f6f6f6;
}
/* Extra Small (xs) - Up to 576px */
@media (max-width: 576px) {
  .csw-modal-container {
    width: 100%;
    max-width: unset;
  }
}
.csw-modal-close-container {
  display: flex;
  min-height: 21px;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 24px;
  cursor: pointer;
  background-color: white;
}
.csw-modal-close-link {
  color: #535353;
  font-size: 14px;
  font-family: sans-serif;
}
.csw-modal-close-link:hover {
  font-weight: bold;
}
.csw-modal {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.csw-iframe {
  border: none;
}
.csw-modal-visible {
  visibility: visible;
}
.csw-modal-hide {
  visibility: hidden;
}
</style>
